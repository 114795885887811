import React from 'react'

const Staffs = () => {
  return (
    <div className="mt-12 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">Staffs

    </div>
  )
}

export default Staffs